// React vendors
@import '~socicon/css/socicon.css';
@import '~@fortawesome/fontawesome-free/css/all.min.css';
@import '~line-awesome/dist/line-awesome/css/line-awesome.css';
@import '~prism-themes/themes/prism-shades-of-purple.css';
@import '~bootstrap-icons/font/bootstrap-icons.css';
@import '~animate.css/animate.css';

#root {
  display: contents;
}

.form-check-input:checked {
  background-color: #3e97ff;
  border-color: #3e97ff !important;
}

.css-15l3djv-control {
  border-radius: 0.65rem !important;
  background-color: #fff !important;
}

.text-dark-yellow {
  color: #bb9200 !important;
}

.text-light-gray {
  color: #898c9f !important;
}

.bg-card {
  background-color: #f5f5f5;
}
.overflow-resp {
  overflow-x: visible;
}

@media (max-width: 1200px) {
  .overflow-resp {
    overflow-x: auto;
  }
}

.table-actions-menu {
  position: absolute;
  top: 120%;
  right: 50%;
  transform: translateY(-50%);
  z-index: 107;
  display: none;
  min-width: 150px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 12px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.table-actions-menu.show {
  display: block;
}
/* Staging label */
.style_lable__3JcLh {
  position: absolute;
  top: 20px;
  right: -20px;
  z-index: 9999;
  transform: rotate(45deg);
  background-color: red;
  width: 100px;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  overflow: hidden;
}

/* Prevent horizontal scrolling */
body {
  overflow-x: hidden;
}
